<template>
    <v-container fluid  pa-2>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center mt-4">
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" class="text-center mt-1">
                    <v-alert
                        :value="prohibitedHours"
                        type="error"
                    >
                    It is currently between 2:00am and 6:00am. To comply with Arizona Liquor Law, orders will not be accepted at this time.
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <v-tabs color="light" grow>
                        <v-tabs-slider color="blue"></v-tabs-slider>
                        <v-tab href="#tab">
                            Available for Pre-Order
                        </v-tab>
                        <v-tab-item :value="'tab'">
                            <v-col cols="12" mt-2>
                                
                                <v-expansion-panels  class="ma-0">
                                    <v-expansion-panel
                                        v-for="(item,n) in products"
                                        :key="`expansion-${n}`"
                                    >
                                    <v-expansion-panel-header>
                               

                                        <ProductHeader
                                        :product="item" 
                                        item-key="productId"
                                        v-bind:index="n"
                                        />
                                
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                    <v-card cols="12">
                                        <Product 
                                        :item="item" 
                                        :disable="prohibitedHours"
                                        item-key="productId"
                                        v-bind:index="n"
                                        />
                                    </v-card>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                
                                <div class="text-center">
                                    <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters} from 'vuex'
import Product from '@/components/product/Product'
import ProductHeader from '@/components/product/ProductHeader'
import api from '@/store/api'
import moment from 'moment-timezone'
import AllMixins from '@/mixins/AllMixins'
moment.updateLocale('en', {
    calendar : {
        lastDay : '[Yesterday at] LT',
        sameDay : '[Today] LT',
        nextDay : 'ddd LT',
        lastWeek : '[last] dddd [at] LT',
        nextWeek : 'ddd LT',
        sameElse : 'l'
    },
    longDateFormat : {
        LT : 'ha',
        LTS: "h:mm:ss A",
        L: "MM/DD/YYYY",
        l: "M/D/YYYY",
        LL: "MMMM Do YYYY",
        ll: "MMM D YYYY",
        LLL: "MMMM Do YYYY LT",
        lll: "MMM D YYYY LT",
        LLLL: "dddd, MMMM Do YYYY LT",
        llll: "ddd, MMM D YYYY LT"
    },
});

export default {
    components: {
        Product,
        ProductHeader
    },
    mixins: [AllMixins],
    mounted () {
        api.loadUserOrders()
        this.getAvailableProducts()
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
        products () {
            let products = this.$store.state.products
            if(!products)
                return []
            else
                return products 
        },
        prohibitedHours(){
        //    let hour = moment().tz("America/Phoenix").format().substring(11,13)
        //    if(hour >= 2 && hour <= 5) //prohibit due to AZ liquor law
        //        return true
            return false
        },
    },
    methods: {
        refresh () {
            this.getAvailableProducts()
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
    }
}
</script>